import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSC.jpg"

function Escalator() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap escalWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap escalOverview">
          <div className="textWrap">
            <h2>Eescalator&amp;Moving Walk</h2>
            <p className="bodyText">
              Escalators and moving walks are developed forms of elevators that
              connect disconnected spaces and help fast movement. In large
              commercial buildings, airports, ports, etc., there are many moving
              populations, and in spaces where maximum efficiency and profits
              can be achieved when minimizing travel and travel time. Mainly
              used. In such a large space, escalator breakdowns and frequent
              accidents not only negatively perceive the user experience of the
              space, but also maximizes the influx of inflowers, which can
              hinder sales and profits. The ELSA Platform enables real-time
              monitoring and control of these large devices, instant
              notification of parts failures, as well as a central control room
              as well as engineers' mobile devices to remotely diagnose and
              process device errors and promptly dispatch and solve problems.
              <br />
              <br />
              Sera S.E., a research and development company for elevator and
              escalator control systems Through 40 years of experience and
              collaboration applying M2MTech's new technology, we are increasing
              the number of sites where ELSA's maintenance system is applied.
              The ELSA Platform used at sites such as Lotte Department Store in
              Busan, Setum City Store, Konkuk University Lotte Star City Store,
              and Bundang Lotte Department Store has been proven to provide
              various functions, reduce maintenance costs, and increase work
              efficiency.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Escalator
